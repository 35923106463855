import React from 'react'
import "./footer.css"

function Footer() {
  return (

    <span className='footer' > Copyright &#169; 2024 SK. All right received. </span>
  )
}

export default Footer